import { Suspense, lazy, useEffect, useState } from "react";
import { MESSAGE_CONTENT_TYPE } from "../../constants";
import { IMessage, IMessageData, IObject, ISendMessage } from "../../interface";
import { connectWS, disconnectWS, initChatWidget, playNotificationSound, sendMessage } from "../../services/chat.service";
import ChatInput from "../ChatInput/ChatInput";
import ChatWindow from "../ChatWindow/ChatWindow";
// import Header from "../Header/Header";
import USWidget from "../MessageContent/components/USWidget/USWidget";
import { WidgetContextProvider, defaultSetting } from "./WidgetContext";
import styles from "./chatWidget.module.css";
import { useSearchParams } from "react-router-dom";

const ConversationClosed = lazy(() => import("../ConversationClosed/ConversationClosed"));

const ChatWidget = () => {
	const [messages, setMessages] = useState<Array<IMessageData>>([]);
	const [setting, changeSetting] = useState(defaultSetting);
	const [searchParam] = useSearchParams();
	// const [wsResponse, setWsResponse] = useState<IMessageData[]>();
	const [updatedMessage, setUpdatedMessage] = useState<IMessageData>();
	const [typing, setTyping] = useState<boolean>(false);
	const [conversationCompletedReason, setConversationCompletedReason] = useState<string | undefined>();
	const { sound } = setting;
	const [usWidget, setUsWidget] = useState<IMessageData | null>(null);
	const [isChatInputDisabled, disableChatInput] = useState<boolean>(false);
	const platform = searchParam.get("platform");
	const ssoId = searchParam.get("ssoId");

	const onSendMessage = (message: { type: string; payload: any; messageData: any }) => {
		if (!messages.some(el => el.role === "CUSTOMER")) {
			//@ts-ignore
			window.gtmDataLayer.push({
				"event": "trackEventNoEcommerce",
				"eventCategory": "chat|flightStatus",
				"eventAction": "flightStatus_chat_initiated",
				"event_name": "flightStatus_chat_initiated",
				"eventLabel": JSON.stringify({ platform, message }),
				"sso_id": ssoId
			})
		}

		//@ts-ignore
		window.gtmDataLayer.push({
			"event": "trackEventNoEcommerce",
			"eventCategory": "chat|flightStatus",
			"eventAction": "flightStatus_message_sent",
			"event_name": "flightStatus_message_sent",
			"eventLabel": JSON.stringify({ platform, message }),
			"sso_id": ssoId
		})

		const { type, payload, messageData } = message;
		const body: ISendMessage = {
			type,
			payload,
			messageData,
		};
		setUsWidget(null);
		sendMessage(body);
	};

	const initChat = (lang?: string) => {
		const param: IObject = {};
		if (lang) {
			param.lang = lang;
		}
		initChatWidget(param).then((resp) => {
			if (resp.status === "OK" && resp.data) {
				setMessages(resp.data.responses.map((el: IMessage) => ({ ...el })));
				connectWS(onNewWsEvent);
			}
		});
	};

	useEffect(() => {
		if (updatedMessage) {
			setMessages((messages) => {
				const index = messages.findIndex((el) => el.bubbleId === updatedMessage.bubbleId);
				if (index > -1) {
					messages[index] = updatedMessage;
				}
				return [...messages];
			});
		}
		// eslint-disable-next-line
	}, [updatedMessage]);

	const onNewMessage = (payload: IMessageData[]) => {
		setMessages((messages) => {
			if (payload.length > 0) {
				if (sound && payload.some((el) => el.role !== "CUSTOMER")) {
					playNotificationSound();
				}
			}

			if (payload[payload.length - 1]?.payload?.disableInput) {
				disableChatInput(true);
			} else {
				disableChatInput(false);
			}

			const flightStatusMessage = messages.find(el => el.type === MESSAGE_CONTENT_TYPE.FLIGHT_STATUS);
			if (flightStatusMessage) {
				//@ts-ignore
				gtmDataLayer.push({
					"event": "trackEventNoEcommerce",
					"eventCategory": "chat|flightStatus",
					"eventAction": "flightStatus_response",
					"event_name": "flightStatus_response",
					"eventLabel": JSON.stringify({ platform, flightStatusMessage }),
					"sso_id": ssoId
				})
			}

			return [...messages, ...payload];
		});
	};

	const onConversationComplete = (reason: string) => {
		setConversationCompletedReason(reason);
	};

	const onNewWsEvent = (payload: { [key: string]: any }) => {
		switch (payload.type) {
			case "agentWriting":
				setTyping(payload.writing);
				break;
			case "newMessages":
				const usWidget: IMessageData[] = payload.messages.filter((m: IMessageData) => m.type === MESSAGE_CONTENT_TYPE.PLAIN_TEXT && m.payload.startsWith("US_WIDGET:"));
				if (usWidget && usWidget.length > 0) setUsWidget(usWidget[0]);
				else onNewMessage(payload.messages);
				break;
			case "messageUpdated":
				setUpdatedMessage(payload.bubble);
				break;
			case "conversationCompleted":
				onConversationComplete(payload.reason);
				break;
			default:
				return;
		}
	};

	const onRestart = () => {
		disconnectWS();
		setConversationCompletedReason("");
		setMessages([]);
		initChat();
	};

	const onLanguageChange = (lang: string) => {
		disconnectWS();
		initChat(lang);
		setMessages([]);
	};

	return (
		<div className={styles.widgetContainer}>
			<WidgetContextProvider onLanguageChange={onLanguageChange} sendMessage={onSendMessage} setting={setting} changeSetting={changeSetting}>
				{/* <Header messages={messages} /> */}
				<ChatWindow messages={messages} typing={typing} />
				{usWidget === null ? <ChatInput disabled={isChatInputDisabled} /> : <></>}
				{usWidget !== null ? <USWidget messageData={usWidget} /> : <></>}
			</WidgetContextProvider>
			{conversationCompletedReason && (
				<Suspense fallback="">
					<ConversationClosed reason={conversationCompletedReason || ""} onRestart={onRestart} />
				</Suspense>
			)}
		</div>
	);
};

export default ChatWidget;
